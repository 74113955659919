import { Box, Grid, useTheme } from "@material-ui/core";
import { CurrencyRate, MarketplaceFilters, Range } from "~/typedef/store";
import React, { memo } from "react";
import {
  RefundAnalyticsChartData,
  useRefundAnalyticsChartByProductQuery,
} from "~/store/mystore/refundAnalytics.redux";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import MultiBarLineChart from "~/components/charts/multiBarLineChart/multiBarLineChart";
import { getConvertedValue } from "~/utils/currencyUtils";
import { useTranslation } from "react-i18next";

interface RefundAnalyticsTableChart {
  mid: string;
  marketplaceFilters: MarketplaceFilters;
  currentRange: Range;
  currentPeriod: DATETIME_PERIODS;
  currentCurrency: string;
  productId: number;
  includeTax: boolean;
  currencyRates: CurrencyRate[];
  homeCurrency: string;
}

const RefundAnalyticsTableChart = memo(function RefundAnalyticsTableChart({
  mid,
  currentRange,
  productId,
  marketplaceFilters,
  includeTax,
  currentPeriod,
  currencyRates,
  homeCurrency,
  currentCurrency,
}: RefundAnalyticsTableChart) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { chartData, currency, isChartDataLoading } =
    useRefundAnalyticsChartByProductQuery(
      {
        mid,
        marketplaceFilters,
        currentRange,
        includeTax,
        productId,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          chartData: data?.chartData ?? [],
          currency: data?.currency ?? homeCurrency,
          isChartDataLoading: isFetching,
        }),
      }
    );

  const percentageKeys: (keyof RefundAnalyticsChartData)[] = [
    "refundRatePercentage",
    "cancellationRatePercentage",
  ];

  const isPercentageKey = (key: keyof RefundAnalyticsChartData): boolean =>
    percentageKeys.includes(key);

  const mappedChartData = chartData.map((data) => {
    const result: RefundAnalyticsChartData = { ...data };
    (Object.keys(data) as (keyof RefundAnalyticsChartData)[]).forEach((key) => {
      if (!isPercentageKey(key)) {
        result[key] = getConvertedValue(
          currencyRates,
          currency,
          currentCurrency,
          data[key]
        );
      }
    });
    return result;
  });

  return (
    <Box p={1}>
      <Grid container spacing={1}>
        <Grid container item xs={12}>
          <MultiBarLineChart
            title={t(CHART_TITLES[currentPeriod])}
            currentPeriod={currentPeriod}
            currencyRates={currencyRates}
            currentCurrency={currentCurrency}
            chartData={mappedChartData}
            isLoading={isChartDataLoading}
            interval={currentRange.interval}
            lines={[
              {
                key: "refundRatePercentage",
                colour: theme.palette.chart.indigo,
                axis: "1",
              },
              {
                key: "cancellationRatePercentage",
                axis: "1",
                colour: theme.palette.chart.red,
              },
            ]}
            bars={[
              {
                key: "refunds",
                colour: theme.palette.primary.main,
                axis: "2",
                isCurrency: true,
                toFixed: 1,
              },
              {
                key: "cancelledSales",
                colour: theme.palette.secondary.main,
                axis: "2",
                isCurrency: true,
                toFixed: 1,
              },
            ]}
            xKey="startTime"
            report={true}
            timezone={currentRange.timezone}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default RefundAnalyticsTableChart;
