import { Filter, MarketplaceFilters, Range, Store } from "@typedef/store";
import { TAG_TYPES, api } from "../apiSlice";
import {
  setError,
  setInfoMessage,
  setWarningMessage,
} from "../globalToast.redux";

import { Dispatch } from "redux";
import { ProductEventResponse } from "~/modules/profitLossProductTable/productTable";
import { TFunction } from "react-i18next";
import { TOO_MANY_REQUESTS } from "http-status-codes";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { shouldUseCache } from "../utils/shouldUseCache";

export const FETCH_PROFITABILITY_CATEGORIES = "FETCH_PROFITABILITY_CATEGORIES";
export const FETCH_PROFITABILITY_CATEGORIES_FETCHING =
  "FETCH_PROFITABILITY_CATEGORIES_FETCHING";

export const FETCH_STORE_PROFITABILITY = "FETCH_STORE_PROFITABILITY";
export const FETCH_STORE_PROFITABILITY_FETCHING =
  "FETCH_STORE_PROFITABILITY_FETCHING";

export const FETCH_OVERVIEW_PROFITABILITY = "FETCH_OVERVIEW_PROFITABILITY";
export const FETCH_OVERVIEW_PROFITABILITY_FETCHING =
  "FETCH_OVERVIEW_PROFITABILITY_FETCHING";

/** Profit Overview Expanded*/
export const FETCH_OVERVIEW_PROFITABILITY_EXPANDED =
  "FETCH_OVERVIEW_PROFITABILITY_EXPANDED";
export const FETCH_OVERVIEW_PROFITABILITY_FETCHING_EXPANDED =
  "FETCH_OVERVIEW_PROFITABILITY_FETCHING_EXPANDED";
export const CLEAR_OVERVIEW_PROFITABILITY_EXPANDED =
  "CLEAR_OVERVIEW_PROFITABILITY_EXPANDED";

export const FETCH_STORE_PROFITABILITY_SPLIT =
  "FETCH_STORE_PROFITABILITY_SPLIT";
export const FETCH_STORE_PROFITABILITY_SPLIT_FETCHING =
  "FETCH_STORE_PROFITABILITY_SPLIT_FETCHING";

export const FETCH_PRODUCT_CHART_PROFITABILITY =
  "FETCH_PRODUCT_CHART_PROFITABILITY";
export const FETCH_PRODUCT_CHART_PROFITABILITY_FETCHING =
  "FETCH_PRODUCT_CHART_PROFITABILITY_FETCHING";

export const UPLOAD_DIRECT_COSTS = "UPLOAD_DIRECT_COSTS";
export const UPLOAD_DIRECT_COSTS_FETCHING = "UPLOAD_DIRECT_COSTS_FETCHING";

export const FETCH_PROFIT_AND_LOSS = "FETCH_PROFIT_AND_LOSS";
export const FETCH_PROFIT_AND_LOSS_FETCHING = "FETCH_PROFIT_AND_LOSS_FETCHING";

interface ProfitabilityState {
  categories: {
    fetching: boolean;
    data?: FinancialCategory[];
  };
  store: {
    fetching: boolean;
    data?: any;
  };
  productByDay: {
    fetching: boolean;
    data?: any; // Specify the type of data if known
  };
  split: {
    fetching: boolean;
    data?: any; // Specify the type of data if known
  };
  overview: {
    fetching: boolean;
    data?: any;
  };
  overviewExpanded: {
    fetching: boolean;
    data?: any;
    params?: any;
  };
  profitAndLoss: {
    [key: string]: {
      fetching: boolean;
      monthly?: {
        data?: any; // Specify the type of data if known
        fetching: boolean;
      };
      summary?: {
        data?: any; // Specify the type of data if known
        fetching: boolean;
      };
    };
  };
}

const initState: ProfitabilityState = {
  categories: {
    fetching: false,
  },
  store: {
    fetching: false,
  },
  productByDay: {
    fetching: false,
  },
  split: {
    fetching: false,
  },
  overview: {
    fetching: false,
  },
  overviewExpanded: {
    fetching: false,
  },
  profitAndLoss: {
    ALL: {
      fetching: false,
    },
  },
};

export const profitability = (state = initState, action: any) => {
  switch (action.type) {
    case FETCH_PROFITABILITY_CATEGORIES:
      return {
        ...state,
        categories: {
          data: action.payload,
          fetching: false,
        },
      };

    case FETCH_PROFITABILITY_CATEGORIES_FETCHING:
      return {
        ...state,
        categories: {
          ...state.categories,
          fetching: true,
        },
      };

    case FETCH_STORE_PROFITABILITY:
      return {
        ...state,
        store: {
          data: action.payload,
          fetching: false,
        },
      };

    case FETCH_STORE_PROFITABILITY_FETCHING:
      return {
        ...state,
        store: {
          ...state.store,
          fetching: true,
        },
      };

    case FETCH_OVERVIEW_PROFITABILITY:
      return {
        ...state,
        overview: {
          ...action.payload,
          fetching: false,
        },
      };

    case FETCH_OVERVIEW_PROFITABILITY_FETCHING:
      return {
        ...state,
        overview: {
          ...state.overview,
          fetching: true,
        },
      };

    case FETCH_OVERVIEW_PROFITABILITY_EXPANDED:
      // eslint-disable-next-line no-use-before-define
      return processOverviewExpanded(state, action.payload);
    case FETCH_OVERVIEW_PROFITABILITY_FETCHING_EXPANDED:
      return {
        ...state,
        overviewExpanded: {
          ...state.overviewExpanded,
          fetching: true,
        },
      };
    case CLEAR_OVERVIEW_PROFITABILITY_EXPANDED:
      return {
        ...state,
        overviewExpanded: {
          fetching: false,
        },
      };

    case FETCH_STORE_PROFITABILITY_SPLIT:
      return {
        ...state,
        split: {
          data: action.payload,
          fetching: false,
        },
      };

    case FETCH_STORE_PROFITABILITY_SPLIT_FETCHING:
      return {
        ...state,
        split: {
          ...state.store,
          fetching: true,
        },
      };

    case FETCH_PRODUCT_CHART_PROFITABILITY_FETCHING:
      return {
        ...state,
        productByDay: {
          ...state.productByDay,
          fetching: true,
        },
      };

    case FETCH_PRODUCT_CHART_PROFITABILITY:
      return {
        ...state,
        productByDay: {
          data: action.payload,
          fetching: false,
        },
      };

    case UPLOAD_DIRECT_COSTS:
      return {
        ...state,
      };

    case UPLOAD_DIRECT_COSTS_FETCHING:
      return {
        ...state,
      };

    case FETCH_PROFIT_AND_LOSS_FETCHING:
      const fetchingKey = action.payload.groupByMonth ? "monthly" : "summary";
      return {
        ...state,
        profitAndLoss: {
          ...state.profitAndLoss,
          [action.payload.mid || "ALL"]: {
            ...state.profitAndLoss[action.payload.mid],
            [fetchingKey]: {
              fetching: true,
            },
          },
        },
      };

    case FETCH_PROFIT_AND_LOSS:
      const key = action.payload.groupByMonth ? "monthly" : "summary";
      return {
        ...state,
        profitAndLoss: {
          ...state.profitAndLoss,
          [action.payload.mid || "ALL"]: {
            ...state.profitAndLoss[action.payload.mid],
            [key]: {
              data: action.payload.data,
              fetching: false,
            },
          },
        },
      };

    default:
      return state;
  }
};

export interface FinancialCategory {
  type: "revenue" | "expense";
  label: string;
  children: FinancialCategory[];
}

export const fetchProfitabilityCategories =
  ({ mid, includeTax }: { mid?: string; includeTax: boolean }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_PROFITABILITY_CATEGORIES_FETCHING });

    try {
      const { data, status } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/profitability/categories`,
        {
          mid,
          includeTax,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_PROFITABILITY_CATEGORIES,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

interface FetchOverviewProfitabilityParams {
  userId: string;
  includeTax: boolean;
  currentRange: Range;
  filteredStores: Store[];
  filter?: Filter;
  pageSize: number;
  pageIndex: number;
}

export const fetchOverviewProfitability =
  (
    params: FetchOverviewProfitabilityParams,
    oldParams: FetchOverviewProfitabilityParams
  ) =>
  async (dispatch: Dispatch) => {
    const { includeTax, currentRange, filter, pageSize, pageIndex } = params;
    if (!shouldUseCache(params, oldParams)) {
      await dispatch({ type: FETCH_OVERVIEW_PROFITABILITY_FETCHING });

      try {
        const { data, status } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/profitability/eventsByStore`,
          {
            includeTax,
            ...currentRange,
            filter,
            pageSize,
            pageIndex,
          }
        );

        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_OVERVIEW_PROFITABILITY,
            payload: { ...data, params },
          });
        } else {
          return setError(dispatch, data.errMsg, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data.errMsg"),
          get(err, "response.status")
        );
      }
    }
    return null;
  };

export const fetchProductChartProfitability =
  ({
    mid,
    includeTax,
    currentRange,
    sellerSku,
    timezone,
    currency,
  }: {
    mid: string;
    includeTax: boolean;
    currentRange: Range;
    sellerSku: string;
    timezone: string;
    currency: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_PRODUCT_CHART_PROFITABILITY_FETCHING });

    try {
      const { data, status } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/profitability/productEventsByTime`,
        {
          mid,
          includeTax,
          sellerSku,
          ...currentRange,
          timezone,
          currency,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_PRODUCT_CHART_PROFITABILITY,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const fetchStoreProfitabilitySplit =
  ({
    mid,
    includeTax,
    currentRange,
    filter,
  }: {
    mid?: string;
    includeTax: boolean;
    currentRange: Range;
    filter?: Filter;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_STORE_PROFITABILITY_SPLIT_FETCHING });

    try {
      const { data, status } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/profitability/profitabilitySplit`,
        {
          mid,
          includeTax,
          ...currentRange,
          filter,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_STORE_PROFITABILITY_SPLIT,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const uploadDirectCosts =
  (
    mid: string,
    marketplaceType: string,
    marketplaceSubtype: string,
    fromDate: number,
    file: File,
    t: TFunction<"translation">
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_DIRECT_COSTS_FETCHING });

    try {
      const formData = new FormData();

      formData.append("mid", mid);
      formData.append("marketplaceType", marketplaceType);
      formData.append("marketplaceSubtype", marketplaceSubtype);
      formData.append("fromDate", fromDate.toString());
      formData.append("file", file);

      const { data, status } = await axios.post(
        `${baseUrl}/api/myStores/profitability/directCosts`,
        formData
      );

      if (isHttpResponseValid(status) && data) {
        const { sellerSkuCount, directCostCount } = data;
        setInfoMessage(
          dispatch,
          t("profitability.uploadSuccessMessage", {
            sellerSkuCount,
            directCostCount,
          })
        );
        return dispatch({
          type: UPLOAD_DIRECT_COSTS,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      const status = get(err, "response.status");

      if (status === TOO_MANY_REQUESTS) {
        return setWarningMessage(
          dispatch,
          t("profitability.uploadStillProcessingMessage"),
          true
        );
      }

      return setError(dispatch, get(err, "response.data.errMsg"), status);
    }
  };

export const fetchOverviewProfitabilityExpanded =
  ({
    includeTax,
    currentRange,
    pageIndex,
    pageSize,
    sortKey,
    sortOrder,
    searchText,
    filter,
  }: {
    includeTax: boolean;
    currentRange: Range;
    pageIndex: number;
    pageSize: number;
    sortKey: string;
    sortOrder: string;
    searchText: string;
    filter?: Filter;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_OVERVIEW_PROFITABILITY_FETCHING_EXPANDED });

    try {
      const { data, status } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/profitability/eventsByStore`,
        {
          includeTax,
          ...currentRange,
          filter,
          pageSize,
          pageIndex,
          sortKey,
          sortOrder,
          searchText,
        }
      );
      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_OVERVIEW_PROFITABILITY_EXPANDED,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const processOverviewExpanded = (state: any, payload: any) => {
  const existingParams = state.overviewExpanded.params;
  const { params, data } = payload;
  const { pageIndex } = params;

  if (existingParams) {
    const { pageIndex: lastPageIndex } = existingParams;
    if (pageIndex === lastPageIndex + 1) {
      const existingRows = state.overviewExpanded.data || [];
      const totalRows = [...existingRows, ...data];
      return {
        ...state,
        overviewExpanded: {
          ...payload,
          data: totalRows,
          fetching: false,
        },
      };
    }
    return state;
  } else {
    if (pageIndex === 0) {
      return {
        ...state,
        overviewExpanded: {
          ...payload,
          fetching: false,
        },
      };
    }
  }
  return state;
};

export const clearProfitOverviewExpanded = () => async (dispatch: Dispatch) => {
  await dispatch({
    type: CLEAR_OVERVIEW_PROFITABILITY_EXPANDED,
  });
};

export const fetchProfitAndLoss =
  ({
    mid,
    includeTax,
    currentRange,
    currentFilter,
    groupByMonth,
    showComparison,
  }: {
    mid?: string;
    includeTax: boolean;
    currentRange: Range;
    currentFilter?: Filter;
    groupByMonth: boolean;
    showComparison?: boolean;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_PROFIT_AND_LOSS_FETCHING,
      payload: { mid, groupByMonth },
    });

    try {
      const { data, status } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/profitability/profitAndLoss`,
        {
          mid,
          includeTax,
          ...currentRange,
          currentFilter,
          groupByMonth,
          showComparison,
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_PROFIT_AND_LOSS,
          payload: { data, mid, groupByMonth },
        });
      } else {
        return setError(dispatch, data.errMsg, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

interface FetchStoreChartProfitabilityReq {
  mid?: string;
  currentRange: Range;
  filter: Filter;
  currency: string;
  includeTax: boolean;
}

interface FetchStoreChartProfitabilityRes {
  events: {
    events: [{ label: string; value: number; date: string; storeId?: number }];
    totalWithSku: number;
  };
}

interface FetchProductProfitabilityRequest {
  mid?: string;
  includeTax: boolean;
  currentRange: Range;
  pageIndex: number;
  pageSize: number;
  sortKey: string;
  sortOrder: string;
  searchText: string;
}

interface FetchProfitAndLossBreakdownRequest {
  // if mid is passed (i.e. for single-store queries), then
  // marketplaceFilters must also be passed in
  mid?: string;
  marketplaceFilters?: MarketplaceFilters;
  currentRange: Range;
  includeTax?: boolean;
  groupByMonth?: boolean;
  showComparison?: boolean;
  currentFilter?: Filter;
}

type FetchVendorProductDirectCostsRequest = {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  productSku: string;
  pageIndex: number;
  pageSize: number;
  isVendor: true;
};

type FetchSellerProductDirectCostsRequest = {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  sellerSku: string;
  pageIndex: number;
  pageSize: number;
  isVendor: false;
};

type FetchProductDirectCostsRequest =
  | FetchVendorProductDirectCostsRequest
  | FetchSellerProductDirectCostsRequest;

export interface HistoricalDirectCost {
  sellerSku: string;
  eventType: string;
  eventCurrency: string;
  eventValue: number;
  eventBasis: string;
  effectiveDateFrom: Date;
  effectiveDateTo: Date | null;
}

type FetchProductDirectCostsResponse = {
  count: number;
  directCosts: HistoricalDirectCost[];
};

export interface ProfitabilityEventGroup {
  eventType: string;
  eventValue: number;
}

export type ProfitBreakdown = {
  productSales: ProfitabilityEventGroup[];
  productSalesTax: ProfitabilityEventGroup[] | undefined;
  refundedSales: ProfitabilityEventGroup[];
  reimbursements: ProfitabilityEventGroup[];
  promotions: ProfitabilityEventGroup[];
  otherIncome: ProfitabilityEventGroup[];
  advertising: ProfitabilityEventGroup[];
  sellingFees: ProfitabilityEventGroup[];
  fulfilmentAndShipping: ProfitabilityEventGroup[];
  refundsAndReturns: ProfitabilityEventGroup[];
  costOfGoods: ProfitabilityEventGroup[];
  otherExpenses: ProfitabilityEventGroup[];
};

export interface ProfitAndLossBreakdownResponse {
  current: ProfitBreakdown[];
  prior: ProfitBreakdown[];
  lastReportDate?: string;
  lastUpdatedAt?: string;
}

interface UpdateProductDirectCostsResponse {
  message?: string;
  status?: string;
}

interface UpdateProductDirectCostsParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  fromDate: number; //unix timestamp
  directCosts: {
    sellerSku: string;
    currency: string;
    value: number;
  };
  successMessage: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    fetchProductProfitability: build.query<
      ProductEventResponse,
      FetchProductProfitabilityRequest
    >({
      query: (params) => {
        const {
          mid,
          includeTax,
          currentRange,
          pageIndex,
          pageSize,
          sortKey,
          sortOrder,
          searchText,
        } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/profitability/productEvents`,
          method: "POST",
          data: {
            mid,
            includeTax,
            ...currentRange,
            pageIndex,
            pageSize,
            sortKey,
            sortOrder,
            searchText,
          },
        };
      },
    }),
    profitabilityChart: build.query<
      FetchStoreChartProfitabilityRes,
      FetchStoreChartProfitabilityReq
    >({
      query: (params) => {
        const { mid, currentRange, filter, currency, includeTax } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/profitability/storeEventsByTime`,
          method: "POST",
          data: {
            mid,
            ...currentRange,
            filter,
            currency,
            includeTax,
          },
        };
      },
    }),
    fetchProfitAndLossBreakdown: build.query<
      ProfitAndLossBreakdownResponse,
      FetchProfitAndLossBreakdownRequest
    >({
      query: (params) => {
        const {
          mid,
          includeTax,
          currentRange,
          showComparison,
          currentFilter,
          marketplaceFilters,
          groupByMonth,
        } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/profitability/profitAndLossBreakdown`,
          method: "POST",
          data: {
            mid,
            includeTax,
            groupByMonth,
            currentRange,
            showComparison,
            currentFilter,
            ...(marketplaceFilters && { marketplaceFilters }),
          },
        };
      },
    }),
    getDirectCostsByProduct: build.query<
      FetchProductDirectCostsResponse,
      FetchProductDirectCostsRequest
    >({
      query: (params) => {
        const { isVendor, ...rest } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/${
            isVendor ? "vendorProfitability" : "profitability"
          }/directCosts/product`,
          method: "POST",
          data: rest,
        };
      },
      providesTags: [TAG_TYPES.ProductDirectCosts],
      onQueryStarted: globalQueryErrorHandler("GetDirectCostsByProduct", false),
    }),
    updateDirectCostsByProduct: build.mutation<
      UpdateProductDirectCostsResponse,
      UpdateProductDirectCostsParams
    >({
      query: (params) => {
        const {
          mid,
          marketplaceType,
          marketplaceSubtype,
          fromDate,
          directCosts,
        } = params;
        return {
          url: `${baseUrl}/api/myStores/profitability/productDirectCosts`,
          method: "POST",
          data: {
            mid,
            marketplaceType,
            marketplaceSubtype,
            fromDate,
            directCosts,
          },
        };
      },
      invalidatesTags: [TAG_TYPES.ProductDirectCosts],
      onQueryStarted: globalQueryErrorHandler(
        "UpdateDirectCostsByProducts",
        false,
        (_, dispatch, args) => {
          setInfoMessage(dispatch, args.successMessage);
        }
      ),
    }),
  }),
});

export const {
  useProfitabilityChartQuery,
  useFetchProductProfitabilityQuery,
  useFetchProfitAndLossBreakdownQuery,
  useGetDirectCostsByProductQuery,
  useUpdateDirectCostsByProductMutation,
} = extendedApiSlice;
