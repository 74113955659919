import { ArrowDropDown, ArrowRight } from "@material-ui/icons";
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { MenuItem, SubMenuItem as SubMenuItemType } from "./sideNavConstants";
import React, { memo, useMemo, useState } from "react";
import { SubMenuItem, isSubItemSelected } from "./subMenuItem";
import { useHistory, useLocation } from "react-router-dom";

import { CurrentStore } from "~/typedef/store";
import { TopLevelItem } from "./sideNavDrawer";
import { User } from "~/typedef/user";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ExpandableIcon = styled(ListItemIcon)`
  min-width: 0;
`;

export const CustomBadge = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 4px 6px;
  margin-left: 5px;
  font-size: 10px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

const CustomListItemText = styled(ListItemText)`
  flex-grow: 0;
`;

const isSelected = (location: any, menuItem: MenuItem) => {
  if (menuItem.link && location.pathname.includes(menuItem.link)) {
    return true;
  }
  if (menuItem.subMenus) {
    return menuItem.subMenus.some((subItem: SubMenuItemType) =>
      isSubItemSelected(location, subItem)
    );
  }
  return false;
};

interface DrawerMenuItemProps {
  open: boolean;
  menuItem: MenuItem;
  setOpen: (open: boolean) => void;
  currentStore?: CurrentStore;
}

export const DrawerMenuItem = memo<DrawerMenuItemProps>(
  function DrawerMenuItem({ menuItem, setOpen, open, currentStore }) {
    const isMenuOpen = () => {
      if (!open) {
        return false;
      }
      return true;
    };

    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const user = useTypedSelector((state) => state.user);
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const [itemOpen, setItemOpen] = useState(isMenuOpen);

    const expandable = menuItem.subMenus;

    const changeLocation = (item: MenuItem | SubMenuItemType) => {
      if (item.link) {
        const urlParts = location.pathname.split("/");
        const searchParams = location.search;
        urlParts.pop();
        urlParts.push(item.link);
        const newLocation =
          urlParts.join("/") + (item.hideSearchParams ? "" : searchParams);
        history.push(newLocation);
        if (smDown) {
          setOpen(false);
        }
      }
    };

    const handleClick = () => {
      if (menuItem.link) {
        changeLocation(menuItem);
      } else {
        setOpen(true);
        setItemOpen(!itemOpen);
      }
    };

    const filteredSubMenuItems = useMemo(() => {
      const subMenuItems = menuItem.subMenus
        ? menuItem.subMenus.map((subItem) => {
            const marketAllowed = subItem.restricted
              ? subItem.restricted.markets.some(
                  (market) =>
                    market === currentStore?.marketplaceSubtype ||
                    market === currentStore?.marketplace
                )
              : true;

            const disabledForMarkets = subItem.disabledFor?.markets ?? [];
            const disabledForCountries =
              subItem.disabledFor?.countries?.[
                currentStore?.marketplace as Marketplace
              ] ?? [];
            const marketDisabled =
              currentStore &&
              disabledForMarkets.includes(
                currentStore.marketplace as Marketplace
              ) &&
              (disabledForCountries.length === 0 ||
                disabledForCountries.includes(currentStore.marketplaceCountry));

            const hasAccess = marketAllowed && !marketDisabled;
            const restrictedAccess = hasAccess && subItem.featureFlagged;
            const userHasAccess = restrictedAccess
              ? user[restrictedAccess as keyof User]
              : true;
            const comingSoon =
              restrictedAccess === "comingSoon" && !user.comingSoon;
            const notInPlan =
              restrictedAccess !== "comingSoon" && !userHasAccess;

            return {
              ...subItem,
              comingSoon,
              notInPlan,
              hasAccess,
              userHasAccess,
              hideFromMenu:
                (!hasAccess && !comingSoon) ||
                (!userHasAccess && subItem.hideIfRestrictedAccess),
            };
          })
        : [];

      return subMenuItems.filter((subItem) => !subItem.hideFromMenu);
    }, [menuItem, currentStore, user]);

    /* Don't render the top-level menu item if the current marketplace
     * doesn't include that functionality */
    if (
      menuItem.restricted &&
      !menuItem.restricted.markets.some(
        (market) =>
          market === currentStore?.marketplaceSubtype ||
          market === currentStore?.marketplace
      )
    ) {
      return null;
    }

    /* Don't render the top-level menu item if it is disabled for the current marketplace & country combination */
    const disabledForMarkets = menuItem.disabledFor?.markets ?? [];
    const disabledForCountries =
      menuItem.disabledFor?.countries?.[
        currentStore?.marketplace as Marketplace
      ] ?? [];
    if (
      currentStore &&
      disabledForMarkets.includes(currentStore.marketplace as Marketplace) &&
      (disabledForCountries.length === 0 ||
        disabledForCountries.includes(currentStore.marketplaceCountry))
    ) {
      return null;
    }

    /* Don't render the top-level menu item if it is feature flagged */
    if (
      menuItem.featureFlagged &&
      !user[menuItem.featureFlagged as keyof User]
    ) {
      return null;
    }

    /* Don't render the top-level menu item if it does not have a link & the filtered sub menu items is empty */
    if (!menuItem.link && expandable && filteredSubMenuItems.length === 0) {
      return null;
    }

    return (
      <>
        <TopLevelItem
          id={menuItem.id}
          selected={isSelected(location, menuItem)}
          isMini={!open}
          hasChildren={expandable}
          button
          key={menuItem.link}
          onClick={handleClick}
          disableGutters
        >
          <Grid
            container
            alignItems="center"
            justifyContent={open ? "flex-start" : "center"}
            wrap="nowrap"
          >
            {open && (
              <Grid item xs={1}>
                {expandable ? (
                  <ExpandableIcon>
                    {itemOpen ? (
                      <ArrowDropDown fontSize="small" />
                    ) : (
                      <ArrowRight fontSize="small" />
                    )}
                  </ExpandableIcon>
                ) : (
                  <Box width="23px " minWidth="23px" />
                )}
              </Grid>
            )}
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={2}
            >
              <Tooltip
                title={open ? "" : (t(menuItem.title) as string)}
                placement="right-end"
              >
                <ExpandableIcon>
                  {React.createElement(menuItem.icon, {
                    fontSize: "small",
                  })}
                </ExpandableIcon>
              </Tooltip>
            </Grid>
            {open && (
              <Grid item container xs={9} alignItems="center">
                <CustomListItemText primary={t(menuItem.title)} />
                {menuItem.isNew && (
                  <CustomBadge>{t("generic.new")}</CustomBadge>
                )}
                {menuItem.isBeta && (
                  <CustomBadge>{t("generic.beta")}</CustomBadge>
                )}
              </Grid>
            )}
          </Grid>
        </TopLevelItem>
        {expandable && open && (
          <Collapse in={itemOpen} timeout="auto">
            <List component="div" disablePadding>
              {filteredSubMenuItems.map((subItem) => {
                return (
                  <SubMenuItem
                    id={subItem.id}
                    key={subItem.link}
                    comingSoon={subItem.comingSoon}
                    notInPlan={subItem.notInPlan}
                    subItem={subItem}
                    location={location}
                    changeLocation={changeLocation}
                  />
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }
);
