import { Box, Switch, Typography } from "@material-ui/core";

import React from "react";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import styled from "styled-components";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

interface SellTypeToggleProps {
  sellType: SellType;
  setSellType: (sellType: SellType) => void;
}

export const SellTypeToggle = ({
  sellType,
  setSellType,
}: SellTypeToggleProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => state.user);

  const handleSellTypeChange = () => {
    const newSellType =
      sellType === SellType.SELL_OUT ? SellType.SELL_IN : SellType.SELL_OUT;
    setSellType(newSellType);
    dispatch(
      upsertPersistentAppSettings(
        {
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            vendorSellType: newSellType,
          },
        },
        user?.isDemoMode ? false : true
      )
    );
  };

  return (
    <FlexBox>
      <Switch
        size="small"
        checked={sellType === SellType.SELL_OUT}
        onClick={handleSellTypeChange}
      />
      <Typography variant="subtitle1" color="textPrimary" noWrap>
        {sellType === SellType.SELL_OUT
          ? t("vendorProfitability.sellOutToggle")
          : t("vendorProfitability.sellInToggle")}
      </Typography>
    </FlexBox>
  );
};
