import { ButtonBase, Input, Menu, MenuItem } from "@material-ui/core";
import React, { ChangeEvent, MouseEventHandler, memo, useState } from "react";

import CurrencyDisplay from "@components/toolbars/toolbarComponents/currencyDisplay";
import StyledArrowDropDown from "../toolbars/toolbarComponents/styledArrowDropdown";
import { distinctCurrenciesByCountryCode } from "@utils/currencyUtils";
import styled from "styled-components";

const CurrencyMenuItem = styled(MenuItem)`
  width: ${({ width }: { width?: number | null }) => width}px;
`;

const CurrencyMenuBase = styled(ButtonBase).withConfig({
  shouldForwardProp: (prop) => !["maxWidth"].includes(prop),
})`
  width: 100%;
  height: 0;
  min-width: 6rem;
  padding: 0 1rem 0 0;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  &:disabled {
    background-color: ${({ theme }) => theme.palette.disabled.light};
    box-shadow: ${({ theme }) => theme.shadows[0]};
    cursor: not-allowed;
  }
`;

const AmountInput = styled(Input)`
  ${({ fullWidth }) => (fullWidth ? null : "width: 12rem")};
  text-align: right;
`;

interface CurrencyInputProps {
  switchCurrency: (currency: string, isDemoMode?: boolean) => void;
  currentCurrency: string;
  setValue: (value: number) => void;
  value: number;
  fullWidth?: boolean;
}
/**
 * An input combining a currency selector and a numeric value
 * representing the amount in that currency
 */
const CurrencyInput = memo<CurrencyInputProps>(function CurrencyInput({
  switchCurrency,
  currentCurrency,
  value,
  setValue,
  fullWidth,
}) {
  const currencies = distinctCurrenciesByCountryCode;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuWidth] = useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );

  const openMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const changeCurrency = (currencyCode: string) => {
    setAnchorEl(null);
    switchCurrency(currencyCode);
  };

  const valueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.valueAsNumber);
  };

  const renderOptions = () => {
    const currencyKeys = Object.keys(currencies) as (keyof typeof currencies)[];
    return currencyKeys.map((countryCode, index) => {
      const currencyCode = currencies[countryCode] as string;
      return (
        <CurrencyMenuItem key={index} width={menuWidth}>
          <CurrencyDisplay
            activeLink
            countryCode={countryCode}
            currencyCode={currencyCode}
            changeCurrency={changeCurrency}
          />
        </CurrencyMenuItem>
      );
    });
  };

  const currencyKeys = Object.keys(currencies) as (keyof typeof currencies)[];

  return (
    <AmountInput
      type="number"
      inputProps={{
        min: 0,
        step: "any",
      }}
      name="currency-input-amount"
      onChange={valueChangeHandler}
      value={parseFloat(value.toString())}
      fullWidth={fullWidth}
      startAdornment={
        <>
          <CurrencyMenuBase onClick={openMenu} id="currency-input">
            <CurrencyDisplay
              activeLink={false}
              countryCode={
                currencyKeys.find(
                  (key) => currencies[key] === currentCurrency
                ) || ""
              }
              currencyCode={currentCurrency}
              changeCurrency={switchCurrency}
            />
            <StyledArrowDropDown />
          </CurrencyMenuBase>
          <Menu
            id="currency-input-menu"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            autoFocus={false}
            marginThreshold={1}
            onClose={() => setAnchorEl(null)}
          >
            {renderOptions()}
          </Menu>
        </>
      }
    />
  );
});

export default CurrencyInput;
