import { Box, useTheme } from "@material-ui/core";
import MultiBarLineChart, {
  MultiBarLineChartProps,
} from "~/components/charts/multiBarLineChart/multiBarLineChart";
import React, { ReactChild, memo } from "react";

import BarChartNoData from "~/components/placeholders/barChartNoData";
import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import { getConvertedValue } from "~/utils/currencyUtils";
import { useRefundAnalyticsChartQuery } from "~/store/mystore/refundAnalytics.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export enum CHART_TYPE {
  GrossSales = "grossSales",
  Refunds = "refunds",
  NetSales = "netSales",
  RefundRatePercentage = "refundRatePercentage",
  CancelledSales = "cancelledSales",
  CancellationRatePercentage = "cancellationRatePercentage",
}

export const DISPLAY_CHART = {
  Bar: "Bar chart",
  Line: "Line chart",
};

export interface ChartOption {
  value: (typeof CHART_TYPE)[keyof typeof CHART_TYPE];
  label: string;
}

interface RefundAnalyticsChartProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  timezone: string;
  includeTax: boolean;
  actions?: ReactChild;
  chartOption: ChartOption;
  isLineChart?: boolean;
}

const RefundAnalyticsChart = memo<RefundAnalyticsChartProps>(
  function RefundAnalyticsChart({
    mid,
    marketplaceType,
    marketplaceSubtype,
    currentPeriod,
    currentRange,
    currentCurrency,
    actions,
    chartOption,
    isLineChart,
    timezone,
    includeTax,
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const { interval } = currentRange;

    const { chartData, currency, isChartDataLoading } =
      useRefundAnalyticsChartQuery(
        {
          mid,
          marketplaceFilters: {
            marketplaceType,
            marketplaceSubtype,
          },
          currentRange,
          includeTax,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            chartData: data?.chartData ?? [],
            currency: data?.currency,
            isChartDataLoading: isFetching,
          }),
        }
      );

    const getFormattedChartData = () => {
      const type = chartOption.value ?? CHART_TYPE.GrossSales;

      const isPercentage = [
        CHART_TYPE.RefundRatePercentage,
        CHART_TYPE.CancellationRatePercentage,
      ].includes(type);

      const mappedData = chartData.map((data) => {
        const current = data.current
          ? !isPercentage
            ? getConvertedValue(
                currencyRates,
                currency,
                currentCurrency,
                data.current[type]
              )
            : data.current[type]
          : 0;

        const prior = data.prior
          ? !isPercentage
            ? getConvertedValue(
                currencyRates,
                currency,
                currentCurrency,
                data.prior[type]
              )
            : data.prior[type]
          : 0;

        return {
          startTime: data.startTime,
          current,
          prior,
        };
      });
      return mappedData || [];
    };

    const chartProps = () => {
      const isMonetaryValue = [
        CHART_TYPE.GrossSales,
        CHART_TYPE.NetSales,
        CHART_TYPE.Refunds,
        CHART_TYPE.CancelledSales,
      ].includes(chartOption.value);

      const currentAndPrior = [
        {
          key: "current",
          colour: theme.palette.primary.main,
          toFixed: 2,
          isCurrency: isMonetaryValue,
          axis: "1" as const,
          unit: !isMonetaryValue ? "%" : undefined,
        },
        {
          key: "prior",
          colour: theme.palette.secondary.main,
          toFixed: 2,
          isCurrency: isMonetaryValue,
          axis: "1" as const,
          unit: !isMonetaryValue ? "%" : undefined,
        },
      ];

      const chartProps: MultiBarLineChartProps = {
        title: t(CHART_TITLES[currentPeriod]),
        currentPeriod,
        currentCurrency,
        currencyRates,
        chartData: getFormattedChartData(),
        isLoading: false,
        lines: [],
        bars: [],
        xKey: "startTime",
        timezone,
        interval,
        tooltipPercentageChange: [
          {
            dataKey1: "current",
            dataKey2: "prior",
            toFixedDigits: 0,
          },
        ],
      };

      if (isLineChart) {
        chartProps.lines.push(...currentAndPrior);
      } else {
        chartProps.bars.push(...currentAndPrior);
      }

      return chartProps;
    };

    return (
      <Panel
        id="widget-refund-analytics-chart"
        title={t("dashboardWidget.refundAnalytics.mainTitle")}
        tooltip={t("dashboardWidget.refundAnalytics.mainTooltip")}
        actions={actions}
        content={
          isChartDataLoading ? (
            <PanelLoading />
          ) : !chartData.length ? (
            <BarChartNoData {...{ currentPeriod }} />
          ) : (
            <Box padding={2}>
              <MultiBarLineChart {...chartProps()} />
            </Box>
          )
        }
      />
    );
  }
);

export default RefundAnalyticsChart;
