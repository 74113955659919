import "draft-js/dist/Draft.css";

import { Badge, Box, Drawer, Fab, Grid } from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  Comment,
  DragHandle,
  Edit,
  Save,
} from "@material-ui/icons";
import React, { KeyboardEvent, MouseEvent, useEffect, useState } from "react";

import Draggable from "react-draggable";
import MultiChannelReportingComments from "./multiChannelReportingComments";
import SingleChannelReportingComments from "./singleChannelReportingComments";
import { css } from "styled-components";
import { get } from "lodash";
import styled from "styled-components";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import useWindowSize from "~/hooks/useWindowSize";

const iconStyles = css`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 24px;
  width: 24px;
  z-index: 10;
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  padding: 5px;
`;

const StyledDragHandle = styled(DragHandle)`
  cursor: grab;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  width: 15px;
  height: 15px;

  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  position: absolute;

  margin-right: 20px;
  margin-bottom: 20px;

  z-index: 1200;
`;

export const ReportingCommentEditIcon = styled(Edit)`
  ${iconStyles}
`;

export const ReportingCommentSaveIcon = styled(Save)`
  ${iconStyles}
`;

const CommentsButton = styled(Fab)`
  border-radius: ${({ theme }) =>
    `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`};
  padding: 0;
  display: flex;
  align-items: stretch;
  height: 60px;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  z-index: 999;
  transition: right 0.3s ease-in-out;
`;

const ChevronWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  align-items: center;
  opacity: 0.75;
  width: 15px;
  border-radius: ${({ theme }) =>
    `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`};
`;

const CommentWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`;

const ChevronIconExpand = styled(ChevronLeft)`
  margin-left: -4px;
`;

const ChevronIconCollapse = styled(ChevronRight)`
  margin-left: -4px;
`;

const CommentIcon = styled(Comment)`
  margin-top: 5px;
`;

const CommentDrawer = styled(Drawer)`
  position: fixed;
  width: 500px;
  border-radius: ${({ theme }) =>
    `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`};
  right: 0 !important;
  left: auto !important;
  top: 64px !important;
  & #drawer-paper {
    border-radius: ${({ theme }) =>
      `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`};
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

export const ReportingCommentHeader = styled(Grid)`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  align-items: center;
`;

const ReportingCommentsDrawer = ({ isMulti }: { isMulti: boolean }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [isDraggingCommentButton, setIsDraggingCommentButton] = useState(false);

  const { height } = useWindowSize();

  const [shouldShowBadge, setShouldShowBadge] = useState(false);

  const [drawerButtonPosition, setDrawerButtonPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const defaultPositionStorage = localStorage.getItem(
      "commentButtonPosition"
    );
    if (defaultPositionStorage) {
      const defaultPosition = JSON.parse(defaultPositionStorage);
      setDrawerButtonPosition(defaultPosition);
    } else if (height) {
      const position = { x: 0, y: height / 2.0 };
      setDrawerButtonPosition(position);
    }
  }, [height]);

  const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
    if (isDraggingCommentButton) {
      return;
    }

    if (
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setAnchorEl(!anchorEl);
  };

  const customTags = useTypedSelector((state) =>
    get(state, "overview.customTags.tags")
  );

  if ((!customTags || customTags.length === 0) && isMulti) {
    return null; // dont render drawer if no custom tags and is multi
  }

  return (
    <div>
      <Draggable
        axis="y"
        handle=".handle"
        position={drawerButtonPosition}
        scale={1}
        bounds={{
          top: 100,
          bottom: (height || 0) - 100,
        }}
        onDrag={(_, data) => {
          setIsDraggingCommentButton(true);
          setDrawerButtonPosition({
            x: 0,
            y: data.y,
          });
        }}
        onStop={(_, element) => {
          // store final position to local storage
          const position = { x: 0, y: element.y };
          localStorage.setItem(
            "commentButtonPosition",
            JSON.stringify(position)
          );

          // timeout to prevent the click action from being triggered
          setTimeout(() => {
            setIsDraggingCommentButton(false);
          }, 100);
        }}
        key={anchorEl ? "comment-button-draggable" : "comment-button"}
      >
        <Box
          zIndex={2000}
          position="fixed"
          top={0}
          right={anchorEl ? "500px" : 0}
        >
          <StyledDragHandle className="handle" />
          <CommentsButton
            variant="extended"
            aria-label="open comments"
            onClick={toggleDrawer}
          >
            <Badge
              badgeContent="i"
              invisible={!shouldShowBadge}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              overlap="rectangular"
            >
              <ChevronWrapper>
                {anchorEl ? (
                  <ChevronIconCollapse fontSize="small" />
                ) : (
                  <ChevronIconExpand fontSize="small" />
                )}
              </ChevronWrapper>
              <CommentWrapper>
                <CommentIcon />
              </CommentWrapper>
            </Badge>
          </CommentsButton>
        </Box>
      </Draggable>

      <CommentDrawer
        disableScrollLock={true}
        anchor="right"
        open={Boolean(anchorEl)}
        hideBackdrop={true}
        PaperProps={{ id: "drawer-paper" }}
      >
        <Box boxSizing={"border-box"} height="100%" width="100%">
          {isMulti ? (
            <MultiChannelReportingComments
              setShouldShowBadge={setShouldShowBadge}
            />
          ) : (
            <SingleChannelReportingComments
              setShouldShowBadge={setShouldShowBadge}
            />
          )}
        </Box>
      </CommentDrawer>
    </div>
  );
};

export default ReportingCommentsDrawer;
