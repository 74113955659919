/* eslint-disable no-undefined */
import { Box, LinearProgress, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

import AlertCard from "~/components/alert/alertCard";
import Bold from "~/components/typography/bold";
import { CombinedLink } from "~/components/links/link";
import { CountryLogo } from "~/img/flags/country_logos";
import { MarketplaceIcon } from "~/img/marketplaces/icons/marketplaceIcon";
import SyncComplete from "~/img/connections/sync_complete.png";
import SyncInProgress from "~/img/connections/sync_in_progress.png";
import { deleteConnectionStatus } from "~/store/connections/connectionStatusV2.redux";
import { fetchConnectionStatusSingle } from "~/store/connections/connectionStatusV2.redux";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ImageWrapper = styled.img`
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

const Link = styled(CombinedLink)`
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
    font-weight: 600;
  `}
`;

interface ConnectionProgressV2Props {
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  mid: string;
  shopName?: string;
}

const ConnectionProgressV2 = memo<ConnectionProgressV2Props>(
  function ConnectionProgressV2({
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    mid,
    shopName,
  }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const connectionStatus = useTypedSelector(
      (state) => state.connectionStatus.connectionStatuses
    ).find(
      (s) =>
        s.marketplaceType === marketplaceType &&
        s.marketplaceSubtype === marketplaceSubtype &&
        s.countryCode === countryCode &&
        s.mid === mid
    );

    const [open, setOpen] = useState(Boolean(connectionStatus));

    const onClose = () => {
      if (connectionStatus?.progress === 100) {
        dispatch(
          deleteConnectionStatus(
            marketplaceType,
            marketplaceSubtype,
            countryCode,
            mid
          )
        );
      }
      setOpen(!open);
    };

    useEffect(() => {
      const fetchData = async () => {
        await dispatch(
          fetchConnectionStatusSingle(
            marketplaceType,
            marketplaceSubtype,
            countryCode,
            mid
          )
        );
      };
      fetchData();
    }, [marketplaceType, marketplaceSubtype, countryCode, mid]);

    return (
      <Box
        display="flex"
        width="100%"
        alignItems="stretch"
        flexDirection="column"
        mb={open ? 2 : 0}
      >
        <AlertCard type="plain" isOpen={open} onClose={onClose}>
          <Box padding={1}>
            <ImageWrapper
              src={
                connectionStatus?.progress === 100
                  ? SyncComplete
                  : SyncInProgress
              }
            />
          </Box>
          <Box padding={1} display="flex" flexDirection="column" width="100%">
            <Bold variant="h5">
              {Boolean(connectionStatus?.progress === 100)
                ? t("connectionProgress.completeTitle")
                : t("connectionProgress.inProgressTitle")}
            </Bold>
            <Typography variant="body2" align="right">
              {connectionStatus?.progress === 100
                ? t("connectionProgress.doneMessage")
                : t("connectionProgress.percentageCompletedMessage", {
                    percentage: connectionStatus?.progress ?? "-",
                  })}
            </Typography>
            <Box marginY={0.5}>
              <LinearProgress
                color="secondary"
                value={connectionStatus?.progress ?? 0}
                variant={"determinate"}
              />
            </Box>
            <Box display="flex" alignItems="center" height="100%">
              <Box
                paddingRight={0.5}
                display="flex"
                alignItems="center"
                height="100%"
              >
                <MarketplaceIcon
                  marketplaceSubtype={marketplaceSubtype}
                  market={marketplaceType}
                  mid={mid}
                />
              </Box>
              <CountryLogo code={countryCode} />
              <Box
                paddingLeft={1}
                display="flex"
                alignItems="center"
                height="100%"
              >
                <Link
                  {...{
                    variant: "body2",
                    to: marketplaceLink(marketplaceType, mid),
                  }}
                >
                  {shopName || mid}
                </Link>
              </Box>
            </Box>
          </Box>
        </AlertCard>
      </Box>
    );
  }
);

export default ConnectionProgressV2;
