import { Box, Grid } from "@material-ui/core";
import React, { memo, useMemo } from "react";

import { COMPARISON_PERIOD } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import { getCurrencyByCountryCode } from "~/utils/currencyUtils";
import { useRefundAnalyticsSummaryQuery } from "~/store/mystore/refundAnalytics.redux";
import { useTranslation } from "react-i18next";

interface RefundAnalyticsSummary {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentCurrency: string;
  currentRange: Range;
  currentCompare: COMPARISON_PERIOD;
  includeTax: boolean;
  conditionalFormatting?: boolean;
  countryCode: string;
  report?: boolean;
}
const RefundAnalyticsSummary = memo<RefundAnalyticsSummary>(
  function RefundAnalyticsSummary({
    mid,
    marketplaceType,
    marketplaceSubtype,
    currentRange,
    conditionalFormatting,
    report,
    countryCode,
    currentCurrency,
    currentCompare,
    includeTax,
  }) {
    const { t } = useTranslation();

    const { summaryData, isLoading } = useRefundAnalyticsSummaryQuery(
      {
        mid,
        marketplaceFilters: {
          marketplaceType,
          marketplaceSubtype,
        },
        currentRange,
        includeTax,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          summaryData: data,
          isLoading: isFetching,
        }),
      }
    );

    const homeCurrency = useMemo(
      () => getCurrencyByCountryCode[countryCode],
      [countryCode]
    );

    return (
      <Panel
        id="widget-refund-analytics-summary"
        title={t(`myStoresWidget.refundAnalyticsSummary`)}
        tooltip={t("myStoresWidget.refundAnalyticsSummaryTooltip")}
        content={
          <Box p={2}>
            {isLoading || !summaryData ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={summaryData.current.grossSales}
                    prior={summaryData.prior.grossSales}
                    tooltip={t(
                      "myStoresWidget.refundAnalytics.summary.grossSales"
                    )}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`refundAnalyticsSummary.grossSales`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                    rounded
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={summaryData.current.netSales}
                    prior={summaryData.prior.netSales}
                    tooltip={t(
                      "myStoresWidget.refundAnalytics.summary.netSales"
                    )}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    title={t(`refundAnalyticsSummary.netSales`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    rounded
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={summaryData.current.cancelledSales}
                    prior={summaryData.prior.cancelledSales}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    tooltip={t(
                      "myStoresWidget.refundAnalytics.summary.cancelledSales"
                    )}
                    title={t(`refundAnalyticsSummary.cancelledSales`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                    rounded
                    reverseFormatting
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotalMoney
                    current={summaryData.current.refundedSales}
                    prior={summaryData.prior.refundedSales}
                    currency={homeCurrency}
                    currentCurrency={currentCurrency}
                    tooltip={t(
                      "myStoresWidget.refundAnalytics.summary.refundedSales"
                    )}
                    title={t(`refundAnalyticsSummary.refundedSales`)}
                    comparisonPeriod={currentCompare}
                    condensedComparison={true}
                    condensed={true}
                    conditionalFormatting={conditionalFormatting}
                    rounded
                    reverseFormatting
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={summaryData.current.cancelRate}
                    comparison={{
                      prior: summaryData.prior.cancelRate,
                    }}
                    tooltip={t(
                      "myStoresWidget.refundAnalytics.summary.cancelRate"
                    )}
                    title={t(`refundAnalyticsSummary.cancelRate`)}
                    condensedComparison={true}
                    condensed={true}
                    percentage
                    reverseFormatting
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
                <Grid item xs={12} md={report ? 4 : 6}>
                  <GenericTotal
                    number={summaryData.current.refundRate}
                    comparison={{
                      prior: summaryData.prior.refundRate,
                    }}
                    tooltip={t(
                      "myStoresWidget.refundAnalytics.summary.refundRate"
                    )}
                    title={t(`refundAnalyticsSummary.refundRate`)}
                    condensedComparison={true}
                    condensed={true}
                    percentage
                    reverseFormatting
                    conditionalFormatting={conditionalFormatting}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default RefundAnalyticsSummary;
