import {
  Box,
  Grid,
  GridSize,
  Hidden,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  HealthStatus,
  getAccountHealth,
  getStatusTitle,
  healthDataConfig,
} from "~/utils/accountHealthUtils";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { AmazonCountry } from "mm-amazon-common/dist/typedef/mws";
import CombinedLink from "~/components/links/link";
import { Filter } from "~/typedef/store";
import { InfoOutlined } from "@material-ui/icons";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import NoData from "~/components/loadingIndicator/noData";
import { Panel } from "~/components/panel/panel";
import StatusIndicator from "~/components/statusIndicator/statusIndicator";
import StatusText from "~/components/typography/status";
import { User } from "~/typedef/user";
import { fetchAccountHealth } from "../../store/overview/accountHealth.redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { marketplace } from "mm-amazon-common/dist/mwsUtils";
import { marketplaceConstants } from "mm-mercado-libre-common/dist/shared/marketplaceConstants";
import moment from "moment-timezone";
import { stripFilteredSuffix } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const InlineIconButton = styled(IconButton)`
  padding: 0;
  margin-top: 3px;
  margin-left: 4px;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: flex-start;
`;

const InfoIcon = styled(InfoOutlined)`
  height: 12px;
`;

interface SpecificData {
  value: any;
  rawValue: any;
  total: any;
  date: any;
  startDate: any;
  endDate: any;
  status: any;
  target: any;
  targetCondition: any;
}

interface PanelData extends SpecificData {
  healthStatus: string;
  shopName: { value: string };
}

export const formatSellerDashboardUrl = (
  link: string,
  market: string,
  shopName: {
    value: string;
  },
  countryCode: string
): string => {
  if (market === "catch" && link) {
    return link.replace(/<shopName>/, shopName.value);
  }

  if (stripFilteredSuffix(market) === "amazon" && link) {
    try {
      const amazonSiteUrl = marketplace(
        countryCode as AmazonCountry
      ).retailHost;
      const amazonSellerUrl = `https://sellercentral.${amazonSiteUrl}`;
      return link.replace(/<amazonSellerUrl>/, amazonSellerUrl);
    } catch (err) {
      return "#";
    }
  }

  if (market === "mercadolibre" && link) {
    try {
      const retailHost: string | undefined =
        marketplaceConstants[countryCode]?.retailHost;
      if (!retailHost) return "#";

      const mercadoLibreUrl = `https://${retailHost}`;
      return link.replace(/<mercadoLibreUrl>/, mercadoLibreUrl);
    } catch (err) {
      return "#";
    }
  }

  return link;
};

function renderHealthPanels(
  market: keyof typeof healthDataConfig,
  mid: string,
  accountHealth: any,
  healthData: PanelData,
  report?: boolean,
  conditionalFormatting?: boolean
) {
  const marketConfig = healthDataConfig[market];

  if (!marketConfig) {
    return (
      <Grid container>
        <Grid item xs={12} md={9} lg={7} xl={4}>
          <HealthMetricPanel
            market={market}
            panelKey={"healthStatus"}
            mid={mid}
            healthData={healthData}
            accountHealth={accountHealth}
            conditionalFormatting={conditionalFormatting}
          />
        </Grid>
      </Grid>
    );
  }

  const marketConfigKeys = Object.keys(
    marketConfig
  ) as (keyof typeof marketConfig)[];

  return (
    <Grid container spacing={2} className={report ? "panel-grid" : undefined}>
      {marketConfigKeys.map((panelKey: keyof typeof marketConfig) => {
        const panelConfig = marketConfig[panelKey];
        return (
          <Grid
            key={panelKey}
            item
            xs={12}
            md={report ? 12 : (panelConfig.colspan as GridSize) || 4}
          >
            <HealthMetricPanel
              panelKey={panelKey}
              accountHealth={accountHealth}
              healthData={healthData}
              market={market}
              mid={mid}
              report={report}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

interface HealthPanelContentProps {
  panelKey: string;
  panelData: PanelData;
  market: string;
  countryCode: string;
  mid: string;
  conditionalFormatting?: boolean;
}

const HealthPanelContent = ({
  panelKey,
  panelData,
  market,
  countryCode,
  mid,
  conditionalFormatting,
}: HealthPanelContentProps) => {
  const { t } = useTranslation();
  const panelConfig: any = get(healthDataConfig, `${market}.${panelKey}`);
  const { healthStatus, shopName, ...specificData } = panelData;

  const specificDataKeys = Object.keys(
    specificData
  ) as (keyof typeof specificData)[];

  return (
    <>
      {panelKey === "healthStatus" && (
        <Box pl={2} pt={3} pb={2}>
          <StatusIndicator
            {...{
              statusText: getStatusTitle(
                healthStatus ? healthStatus : HealthStatus.NoStatus,
                t
              ),
              status: healthStatus ? healthStatus : null,
              title: t("dashboardWidget.accountHealth.healthStatus"),
              conditionalFormatting: conditionalFormatting,
            }}
          />
        </Box>
      )}
      <Box p={2} pt={2}>
        <Grid container spacing={2}>
          {specificDataKeys.map((key: keyof typeof specificData) => {
            const metric = specificData[key];
            const itemConfig = panelConfig?.items?.find(
              (item: any) => item.key === key
            );
            return (
              itemConfig && (
                <HealthListItem
                  key={key}
                  value={metric.value}
                  rawValue={metric.rawValue}
                  total={metric.total}
                  date={metric.date}
                  startDate={metric.startDate}
                  endDate={metric.endDate}
                  status={metric.status}
                  target={metric.target}
                  targetCondition={metric.targetCondition}
                  title={key}
                  market={market}
                  shopName={shopName}
                  config={itemConfig}
                  countryCode={countryCode}
                  mid={mid}
                  conditionalFormatting={conditionalFormatting}
                />
              )
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

interface HealthMetricPanelProps {
  panelKey: string;
  healthData: PanelData;
  market: string;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  mid: string;
  accountHealth: any;
  report?: boolean;
  conditionalFormatting?: boolean;
}

const HealthMetricPanel = ({
  panelKey,
  footerLink,
  accountHealth,
  healthData,
  market,
  mid,
  report,
  conditionalFormatting,
}: HealthMetricPanelProps) => {
  const { t } = useTranslation();
  const panelConfig: any = get(healthDataConfig, `${market}.${panelKey}`);
  const { healthStatus, shopName, ...specificData } = healthData || {};
  const countryCode = get(
    accountHealth,
    "accountHealth.marketplace.countryCode"
  );
  const url =
    panelConfig && panelConfig?.url && countryCode
      ? formatSellerDashboardUrl(
          panelConfig?.url,
          market,
          shopName,
          countryCode
        )
      : null;
  return (
    <Panel
      id="widget-account-health"
      title={t(`dashboardWidget.accountHealth.${panelKey}`)}
      footerLink={
        (!report && footerLink) ||
        (!report &&
          url && {
            url,
            external: true,
            label: t("dashboardWidget.accountHealth.viewDetailsLink"),
          }) ||
        undefined
      }
      content={
        panelConfig && !isEmpty(specificData) ? (
          <HealthPanelContent
            panelKey={panelKey}
            panelData={healthData}
            market={market}
            countryCode={countryCode}
            mid={mid}
            conditionalFormatting={conditionalFormatting}
          />
        ) : !panelConfig || isEmpty(healthData) ? (
          <Box
            p={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <NoData
              componentToRender={
                panelConfig ? null : (
                  <Typography align="center">
                    {t("dashboardWidget.accountHealth.noDataAvailable", {
                      marketplace: market,
                    })}
                  </Typography>
                )
              }
            />
          </Box>
        ) : (
          <LoadingIndicator />
        )
      }
    />
  );
};

const formatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
});
const formatNumber = (value: number | string) =>
  typeof value === "number" ? formatter.format(value) : value;

interface HealthListItemProps {
  title: string;
  subtitle?: string;
  value: any;
  date?: string;
  startDate?: string;
  endDate?: string;
  rawValue?: number;
  total?: number;
  status?: string;
  target?: number;
  targetCondition?: string;
  timePeriodDays?: number;
  market: string;
  countryCode: string;
  shopName: {
    value: string;
  };
  config: any;
  mid: string;
  conditionalFormatting?: boolean;
}

const HealthListItem = ({
  title,
  value,
  rawValue,
  total,
  date,
  startDate,
  endDate,
  status,
  target,
  targetCondition,
  market,
  countryCode,
  shopName,
  config,
  mid,
  conditionalFormatting,
}: HealthListItemProps) => {
  const { t } = useTranslation();
  const printedValue =
    value === null || typeof value === "undefined" || value.toString() === "NaN"
      ? "-"
      : formatNumber(value);
  const tooltip = config && config.tooltip && t(config.tooltip);
  const targetValue = formatNumber(
    target == null ? get(config, `target`) : target
  );
  const subtitle = targetValue
    ? t("dashboardWidget.accountHealth.target", {
        target: `${targetValue}`,
        targetCondition: `${targetCondition || config.targetCondition}`,
        suffix: (config.suffix && t(config.suffix)) || "",
      })
    : null;
  const link = config && config.url;
  const routerLink = config && config.route;
  const valueSubtitle =
    config && config.displayTotals
      ? total
        ? t("dashboardWidget.accountHealth.valueTotal", {
            rawValue,
            total,
            units: t(
              config.units != null
                ? config.units
                : "dashboardWidget.accountHealth.units.orders"
            ),
          })
        : t("dashboardWidget.accountHealth.rawValue", {
            rawValue,
            units: t(
              config.units != null
                ? config.units
                : "dashboardWidget.accountHealth.units.orders"
            ),
          })
      : null;
  const timePeriodDays =
    config && config.timePeriodDays
      ? t("dashboardWidget.accountHealth.timePeriod", {
          timePeriodDays: config.timePeriodDays,
        })
      : null;
  const timePeriodRange =
    config && config.displayTimePeriod && startDate && endDate
      ? t("dashboardWidget.accountHealth.timePeriodRange", {
          startDate: moment(startDate).format("MMMM Do YYYY"),
          endDate: moment(endDate).format("MMMM Do YYYY"),
        })
      : null;
  const timestamp =
    config && config.useTimestamp && date
      ? t("dashboardWidget.accountHealth.timestamp", {
          date: moment(date).format("MMMM Do YYYY"),
        })
      : null;

  const linkWithValues = formatSellerDashboardUrl(
    link,
    market,
    shopName,
    countryCode
  );

  return (
    <Grid container item xs={6} direction="column">
      <FlexGrid item>
        <Typography variant="body2" color="textPrimary">
          {config ? t(config.title) : title}
        </Typography>
        {tooltip && (
          <Hidden xsDown>
            <Tooltip title={tooltip} id="panel-card-tooltip">
              <InlineIconButton aria-label="info" id="panel-card-tooltip-icon">
                <InfoIcon fontSize="small" />
              </InlineIconButton>
            </Tooltip>
          </Hidden>
        )}
      </FlexGrid>
      <Grid item>
        {subtitle && (
          <Typography variant="subtitle2" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <StatusText
          variant="body1"
          status={conditionalFormatting === false ? undefined : status}
          {...(link
            ? {
                component: Link,
                href: linkWithValues,
                target: "_blank",
              }
            : {})}
          {...(routerLink
            ? {
                component: CombinedLink,
                to: {
                  pathname: routerLink,
                  search: `?store=${encodeURIComponent(mid)}`,
                },
              }
            : {})}
        >
          {printedValue}
          {config &&
            printedValue !== "N/A" &&
            printedValue !== "-" &&
            config.suffix &&
            t(config.suffix)}
        </StatusText>
        {valueSubtitle && (
          <Typography variant="subtitle1" color="textSecondary">
            {valueSubtitle}
          </Typography>
        )}
        {timestamp && (
          <Typography variant="subtitle1" color="textSecondary">
            {timestamp}
          </Typography>
        )}
      </Grid>
      {timePeriodDays && (
        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {timePeriodDays}
          </Typography>
        </Grid>
      )}
      {timePeriodRange && (
        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {timePeriodRange}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const defaultHealthData = {
  healthStatus: HealthStatus.NoStatus,
};

interface AccountHealthProps {
  userInfo: User;
  mid: string;
  market: keyof typeof healthDataConfig;
  marketplaceSubtype?: keyof typeof healthDataConfig;
  currentFilter?: Filter;
  footerLink?: {
    url: any;
    external?: boolean;
    label?: string;
  };
  report?: boolean;
  condensed?: boolean;
  conditionalFormatting?: boolean;
  timezone: string;
}

const AccountHealth = memo<AccountHealthProps>(function AccountHealth({
  userInfo,
  market,
  marketplaceSubtype,
  mid,
  condensed,
  currentFilter,
  footerLink,
  report,
  conditionalFormatting,
  timezone,
}) {
  const accountHealth = useTypedSelector(
    (state) => state.mystore.accountHealth
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const userCheck = userInfo && userInfo._id;

  const dispatchFetchAccountHealth = useCallback(
    (user, storeMid, filter) => {
      dispatch(
        fetchAccountHealth({
          user: { _id: user._id, organisationId: user.organisationId },
          mid: storeMid,
          filter,
          timezone,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatchFetchAccountHealth(userInfo, mid, currentFilter);
      setLoading(false);
    };
    if (userCheck) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, mid, userCheck]);

  const healthData = useMemo(() => {
    // need to maintain own loading state within this component because
    // "market" and "accountHealth" can become temporarily out of sync when
    // seller switches from, say, an eBay store to an Amazon store, which
    // causes issues within getAccountHealth()
    if (!loading && !accountHealth?.fetching && accountHealth?.accountHealth) {
      return (
        getAccountHealth(market, accountHealth.accountHealth) ||
        defaultHealthData
      );
    }
    return defaultHealthData;
  }, [loading, accountHealth?.fetching, accountHealth?.accountHealth, market]);

  return condensed ? (
    <HealthMetricPanel
      panelKey={"healthStatus"}
      footerLink={footerLink}
      healthData={healthData}
      accountHealth={accountHealth}
      market={market}
      mid={mid}
      report={report}
      conditionalFormatting={conditionalFormatting}
    />
  ) : (
    renderHealthPanels(
      marketplaceSubtype || market,
      mid,
      accountHealth,
      healthData,
      report,
      conditionalFormatting
    )
  );
});

export default AccountHealth;
