import * as React from "react";

import { Grid, Modal } from "@material-ui/core";

import Panel from "./panel";
import styled from "styled-components";

const ModalWrapper = styled(Grid)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem 0;
`;

const ModalCloseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const ModalContent = styled(Grid)`
  position: relative;
  z-index: 2;
  pointer-events: auto;
`;

interface ModalPanelProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  subtitle?: string;
  content: React.ReactChild;
  onClose?: () => void;
}
const ModalPanel: React.FC<ModalPanelProps> = ({
  open,
  setOpen,
  title,
  subtitle,
  content,
  onClose,
}) => {
  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted={false}
    >
      <ModalWrapper container alignItems="center" justifyContent="center">
        <ModalCloseWrapper onClick={handleClose} />
        <ModalContent item xs={11} sm={10} md={8} lg={6} xl={6}>
          <Panel
            id="modal-panel"
            title={title}
            content={content}
            subtitle={subtitle}
          />
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
};

export default ModalPanel;
