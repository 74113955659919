import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo } from "react";

import CampaignCustomGroupTable from "~/modules/widgets/customGroups/advertising/campaignCustomGroups";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MarketingAuthoriseUser from "~/modules/marketing/authoriseAdvertising";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { StoreState } from "~/typedef/store";
import get from "lodash/get";
import moment from "moment-timezone";
import { useAdvertisingConnected } from "~/hooks/useAdvertisingConnected";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

const CampaignsByGroupPage = memo(function CampaignsByGroupPage() {
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const { isAdvertisingAuthorised, isReconnectAdvertising } =
    useAdvertisingConnected(store);
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  return (
    <PageBlock>
      {!isAdvertisingAuthorised ? (
        <MarketingAuthoriseUser isReconnect={isReconnectAdvertising} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CampaignCustomGroupTable
              mid={store.merchantId}
              marketplaceType={store.marketplace}
              marketplaceSubtype={store.marketplaceSubtype ?? store.marketplace}
              countryCode={store.marketplaceCountry}
              currentRange={currentRange}
              currentPeriod={currentPeriod}
              currentCurrency={currentCurrency}
              pageSize={PAGE_SIZE}
            />
          </Grid>
        </Grid>
      )}
    </PageBlock>
  );
});

export default CampaignsByGroupPage;
