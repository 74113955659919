import React, { memo } from "react";

import AccountHealthComponent from "~/modules/overview/accountHealth";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import get from "lodash/get";
import { healthDataConfig } from "~/utils/accountHealthUtils";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const AccountHealth = memo(function AccountHealth() {
  const marketplace = useMarketplace();
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  return (
    <PageBlock>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AccountHealthComponent
            market={marketplace as keyof typeof healthDataConfig}
            mid={store.merchantId}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default AccountHealth;
