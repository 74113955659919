import { MarketplaceFilters, Range } from "~/typedef/store";

import { PaginationArgs } from "~/typedef/pagination";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface RefundSummaryArgs {
  mid: string;
  includeTax: boolean;
  currentRange: Range;
  marketplaceFilters: {
    marketplaceType: string;
    marketplaceSubtype: string;
  };
}

interface RefundSummaryData {
  grossSales: number;
  netSales: number;
  cancelledSales: number;
  refundedSales: number;
  cancelRate: number;
  refundRate: number;
}

export interface RefundSummaryResponse {
  current: RefundSummaryData;
  prior: RefundSummaryData;
}

interface RefundAnalyticsChartArgs {
  mid: string;
  marketplaceFilters: MarketplaceFilters;
  currentRange: Range;
  includeTax: boolean;
  productId?: number;
}

export interface RefundAnalyticsChartData {
  startTime: number;
  grossSales: number;
  refunds: number;
  netSales: number;
  refundRatePercentage: number;
  cancellationRatePercentage: number;
  cancelledSales: number;
}

export interface RefundAnalyticsChartResponse {
  chartData: {
    startTime: number;
    current: RefundAnalyticsChartData;
    prior: RefundAnalyticsChartData;
  }[];
  currency: string;
}

export interface RefundChartResponseByProduct {
  chartData: RefundAnalyticsChartData[];
  currency: string;
}

interface RefundProductsArgs {
  mid: string;
  currentRange: Range;
  paginationParams: PaginationArgs;
  marketplaceFilters: MarketplaceFilters;
  includeTax?: boolean;
  searchText?: string;
}

type ComparisonData = {
  current: number;
  prior: number;
};

export interface MappedRefundProduct {
  title: string;
  imageUrl: string;
  linkUrl: string;
  productId: string;
  sellerSku: string;
  asin: string;
  grossSales: ComparisonData;
  grossQty: ComparisonData;
  refundedSales: ComparisonData;
  cancelledSales: ComparisonData;
  unitsRefunded: ComparisonData;
  unitsCancelled: ComparisonData;
  refundRate: ComparisonData;
  refundRateUnits: ComparisonData;
  cancelRate: ComparisonData;
}
export interface RefundProductResponse {
  products: MappedRefundProduct[];
  count: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    refundAnalyticsChart: build.query<
      RefundAnalyticsChartResponse,
      RefundAnalyticsChartArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/refundAnalytics/refundsChart`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("RefundAnalyticsChart"),
    }),
    refundAnalyticsChartByProduct: build.query<
      RefundChartResponseByProduct,
      RefundAnalyticsChartArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/refundAnalytics/refundsChart`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("RefundAnalyticsChartByProduct"),
    }),
    refundAnalyticsSummary: build.query<
      RefundSummaryResponse,
      RefundSummaryArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/refundAnalytics/refundsSummary`,
          method: "POST",
          data: {
            ...params,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("RefundAnalyticsSummary"),
    }),
    refundAnalyticsProducts: build.query<
      RefundProductResponse,
      RefundProductsArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/refundAnalytics/refundsProducts`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("RefundAnalyticsProducts"),
    }),
  }),
});

export const {
  useRefundAnalyticsChartQuery,
  useRefundAnalyticsChartByProductQuery,
  useRefundAnalyticsSummaryQuery,
  useRefundAnalyticsProductsQuery,
} = extendedApiSlice;
