import { Box, FormControlLabel, Grid, Switch } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useMemo, useState } from "react";
import RefundAnalyticsChart, {
  CHART_TYPE,
  ChartOption,
  DISPLAY_CHART,
} from "~/modules/widgets/refundAnalytics/refundAnalyticsChart";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import RefundAnalyticsByProduct from "~/modules/widgets/refundAnalytics/refundAnalyticsProductTable";
import RefundAnalyticsSummary from "~/modules/widgets/refundAnalytics/refundAnalyticsSummary";
import { SettingsMenuItem } from "~/components/appSettings/menu";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import { getExchangeRate } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const RefundAnalytics = memo(function RefundAnalytics() {
  const { t } = useTranslation();

  const CHART_OPTIONS: ChartOption[] = useMemo(
    () => [
      {
        value: CHART_TYPE.GrossSales,
        label: t("refundAnalytics.chartSelect.grossSales"),
      },
      {
        value: CHART_TYPE.Refunds,
        label: t("refundAnalytics.chartSelect.refunds"),
      },
      {
        value: CHART_TYPE.NetSales,
        label: t("refundAnalytics.chartSelect.netSales"),
      },
      {
        value: CHART_TYPE.RefundRatePercentage,
        label: t("refundAnalytics.chartSelect.refundRate"),
      },
      {
        value: CHART_TYPE.CancelledSales,
        label: t("refundAnalytics.chartSelect.cancelledSales"),
      },
      {
        value: CHART_TYPE.CancellationRatePercentage,
        label: t("refundAnalytics.chartSelect.cancellationRatePercentage"),
      },
    ],
    []
  );

  const [chartOption, setChartOption] = useState<ChartOption>(CHART_OPTIONS[0]);
  const [lineChart, setLineChart] = useState<boolean>(false);

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const switchChartOption = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartOption(option);
    }
  };

  const chartToggle: Omit<SettingsMenuItem, "title" | "tooltip"> = {
    checked: lineChart,
    setChecked: (newValue: boolean) => setLineChart(newValue),
  };

  const currencyRates = useTypedSelector((state) =>
    get(state, "globalVar.currencyRates")
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const exchangeRate = getExchangeRate(
    currencyRates,
    getCurrencyByCountryCode[store.marketplaceCountry],
    currentCurrency
  );

  const actions = (
    <>
      <TableFilter
        {...{
          width: "180px",
          options: CHART_OPTIONS,
          currentValue: chartOption.label,
          handleChange: switchChartOption,
        }}
      />
      <Box pl="16px">
        <FormControlLabel
          control={includeSwitch(chartToggle)}
          label={chartToggle.checked ? DISPLAY_CHART.Line : DISPLAY_CHART.Bar}
        />
      </Box>
      <DownloadCsv
        reportType="refundAnalyticsChart"
        path="/api/generic/refundAnalytics/chart"
        mid={store.merchantId}
        params={{
          mid: store.merchantId,
          currentRange,
          marketplaceType: store.marketplace,
          marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
          includeTax,
          currency: currentCurrency,
          exchangeRate,
        }}
      />
    </>
  );

  const tableActions = (
    <DownloadCsv
      reportType="refundAnalyticsByProduct"
      path="/api/generic/refundAnalytics/product"
      mid={store.merchantId}
      params={{
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
        currentRange,
        includeTax,
        currency: currentCurrency,
        exchangeRate,
      }}
    />
  );

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <RefundAnalyticsChart
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            currentPeriod={currentPeriod}
            currentRange={currentRange}
            timezone={selectedTimezone}
            currentCurrency={currentCurrency}
            chartOption={chartOption}
            actions={actions}
            isLineChart={lineChart}
            includeTax={includeTax}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RefundAnalyticsSummary
            mid={store.merchantId}
            includeTax={includeTax}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            currentCurrency={currentCurrency}
            currentRange={currentRange}
            currentCompare={currentCompare}
            countryCode={store.marketplaceCountry}
          />
        </Grid>
        <Grid item xs={12}>
          <RefundAnalyticsByProduct
            currentPeriod={currentPeriod}
            mid={store.merchantId}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            countryCode={store.marketplaceCountry}
            currentCurrency={currentCurrency}
            currentRange={currentRange}
            includeTax={includeTax}
            actions={tableActions}
            currencyRates={currencyRates}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default RefundAnalytics;

const includeSwitch = (item: Omit<SettingsMenuItem, "title" | "tooltip">) => {
  return (
    <Switch
      size="small"
      color={item.colour ?? "secondary"}
      checked={item.checked}
      onClick={() => item.setChecked(!item.checked)}
    />
  );
};
